<script>
  import { Scatter } from 'vue-chartjs';
  import { getColorValue } from '@/utils/color';
  import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

  export default {
    extends: Scatter,

    props: {
      coefficients: {
        type: Array,
        default: null,
      },
      measures: {
        type: Array,
        required: true,
      },
      countingBreakPoint: {
        type: Number,
        default: null,
      },
      occupancyBreakPoint: {
        type: Number,
        default: null,
      },
      countingValue: {
        type: Number,
        default: null,
      },
      occupancyValue: {
        type: Number,
        default: null,
      },
    },

    data () {
      return {
        types: [],
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t('messages.views.admin.components.regulation.smartway.trendChart.occupancy'),
              },
              ticks: {
                min: 0,
                max: 100,
              },
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t('messages.views.admin.components.regulation.smartway.trendChart.counting'),
              },
              ticks: {
                min: 0,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const datasets = [{
          data: this.computedMeasures,
          pointBorderColor: getColorValue('blue'),
          pointBackgroundColor: getColorValue('blue', 'base', 0.1),
          pointRadius: 3,
          pointHoverRadius: 5,
        }];

        if (this.coefficients !== null) {
          datasets.push({
            data: this.getTrendPoints(),
            borderColor: getColorValue('red'),
            borderWidth: 1,
            fill: false,
            tension: 0,
            showLine: true,
          });
        }

        if (this.countingValue !== null && this.occupancyValue !== null) {
          datasets.push({
            data: [{
              x: this.occupancyValue,
              y: this.countingValue,
            }],
            pointBorderColor: getColorValue('red'),
            pointBackgroundColor: getColorValue('red', 'base', 1),
            pointRadius: 5,
            pointHoverRadius: 10,
          });
        }

        return {
          datasets: datasets,
        };
      },
      computedMeasures () {
        return this.measures.map(measure => ({
          x: measure.occupancy,
          y: measure.counting,
        }));
      },
    },

    watch: {
      chartData () {
        this.render({
          animation: {
            duration: false,
          },
        });
      },
    },

    mounted () {
      this.addPlugin([chartjsPluginAnnotation]);
      this.render();
    },

    methods: {
      render (options = {}) {
        if (this.countingBreakPoint !== null && this.occupancyBreakPoint !== null) {
          // Encode then Decode from JSON to solve annotation plugin issue
          // https://github.com/chartjs/chartjs-plugin-annotation/issues/188#issuecomment-730083242
          options = JSON.parse(JSON.stringify(options));

          options.annotation = {
            annotations: [
              {
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-1',
                value: this.occupancyBreakPoint,
                borderDash: [5, 5],
                borderColor: this.$vuetify.theme.themes.light.primary,
                borderWidth: 1,
              },
              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-1',
                value: this.countingBreakPoint,
                borderDash: [5, 5],
                borderColor: this.$vuetify.theme.themes.light.primary,
                borderWidth: 1,
                label: {
                  backgroundColor: this.$vuetify.theme.themes.light.primary,
                  content: this.$t('messages.views.admin.components.regulation.smartway.trendChart.breakPoint'),
                  position: 'right',
                  enabled: true,
                },
              },
            ],
          };
        }

        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      getTrendPoints () {
        const points = [];
        for (let x = 0; x <= 100; x++) {
          points.push({
            x: x,
            y: this.computeY(x),
          });
        }

        return points;
      },
      computeY (x) {
        let y = 0;

        for (const i in this.coefficients) {
          if (i === 0) {
            y += this.coefficients[i];
          } else {
            y += this.coefficients[i] * Math.pow(x, i);
          }
        }

        return y;
      },
    },
  };
</script>
